body {
  margin: 0;
  overflow: hidden;
  font-family: noto-sans, "Noto Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #343b41;
}


.menu-item-base {
  font-family: noto-sans, "Noto Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
  color: #343F5E;
  font-size: 18px;
  font-weight: 700;
}

label {
  color: #526069;
  font-weight: 600;
  font-size: 14px;
}

input {
  font-family: noto-sans, "Noto Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  color: #526069;
  font-weight: 600;
  font-size: 14px;
}


.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 10px;
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.cursor-pointer {
  cursor: pointer;
}

:focus {
  outline: 0;
}

.MuiDialog-root {
  .MuiPickersModal-dialogRoot.MuiDialog-paper {
    padding: 0 0 16px 0;

    .MuiDialogActions-root.MuiDialogActions-spacing {
      padding-right: 16px;
    }
  }
}

.MuiTextField-root.MuiFormControl-root.min-width-override {
  width: 340px;
}


.MuiFormControl-root textarea {
  padding: 0 !important;
}

/* Hide webpack error overlay. Used in development only */
#webpack-dev-server-client-overlay {
  display: none;
}